<template>
  <section class="home">
    <div class="container">
      <Token-modal :isModalOpen="isModalOpen" :isShowClose="true" @close="close">
        <template #body>
          <div class="home__modal">
            <div class="home__modal-tilte major-title">ВНИМАНИЕ!</div>
            <div class="home__modal-before-tilte">Вы не авторизированы на сайте.</div>
            <p class="home__modal-text">
              Для сохранения результатов расчёта в личном кабинете необходимо авторизоваться на
              сайте <a target="_blank" href="https://nav.tn.ru/">nav.tn.ru</a> и начать расчёт из
              личного кабинета для выбранного объекта.
            </p>
            <p class="home__modal-text">
              Для продолжения расчёта без сохранения результатов в личном кабинете закройте текущее
              диалоговое окно.
            </p>
          </div>
        </template>
      </Token-modal>
      <div class="home__wrapper">
        <div class="home__title-wrapper">
          <div class="major-title home__title">
            {{ $t('message.home.title') }}
          </div>
          <div
            v-on:
            class="home__title-bell"
            v-if="$store.state.TNTokenStatus && isBellShow"
            v-on:click="showGroupModal"
          >
            <div class="home__title-counter">1</div>
          </div>
        </div>
        <img
          src="@/assets/img/banner-mobile.jpg"
          class="home__banner-mobile"
          alt="banner"
          width="100%"
        />
        <img
          src="@/assets/img/banner-tablet.jpg"
          class="home__banner-tablet"
          alt="banner"
          width="100%"
        />
        <div class="home__banner">
          <header-user class="home__banner-enter" />
          <img :src="this.$t('message.home.logo')" class="home__banner-logo" alt="logo" />
          <router-link
            :to="`/${$i18n.locale}/systems/0`"
            class="home__banner-btn"
            :class="{ disabled: isBtnDisabled }"
          >
            {{ $t('message.home.btn') }}
            <!--<img src="@/assets/img/icons/preloader.gif" class="preloader" alt="→" v-if="isBtnDisabled">-->
            <loading
              :active.sync="isBtnDisabled"
              v-if="isBtnDisabled"
              :can-cancel="false"
              color="#fff"
              :height="30"
              :width="30"
              :opacity="1"
              backgroundColor="transparent"
              :z-index="3"
              :is-full-page="false"
            ></loading>
            <img src="@/assets/img/arrow.svg" alt="→" v-else />
          </router-link>
        </div>
        <div class="home__info">
          <div class="home__info-text">{{ $t('message.home.majorText') }}</div>
          <div class="home__info-call">
            <div class="text">{{ $t('message.home.question') }}</div>
            <a :href="`tel:${$t('message.home.phone')}`" class="link">
              {{ $t('message.home.phone') }}
            </a>
          </div>
        </div>
        <div class="home__minorInfo">
          <div class="home__minorInfo-text">
            {{ $t('message.home.minorText') }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import HeaderUser from '@/components/smart/HeaderUser'
import TokenModal from '@/components/elements/Modals/Modal'
import { stepNumbers } from '@/utils/stepNumbers'
export default {
  data() {
    return {
      isBellShow: true,
      isModalOpen: false,
      isShow: true
    }
  },
  components: {
    TokenModal,
    HeaderUser
  },
  methods: {
    ...mapActions({
      fetchData: 'fetchData',
      checkTNTokenAction: 'checkTNToken',
      updateTNToken: 'updateTNToken',
      updateStep: 'updateStep'
    }),
    checkTNToken() {
      const token = this.$route.query.tnt
      if (token) {
        this.checkTNTokenAction(token)
      } else {
        this.updateTNToken()
      }
    },
    showGroupModal() {
      this.isModalOpen = true
    },
    close() {
      this.isModalOpen = false
      this.isBellShow = false
    }
  },
  // watch: {
  //   '$store.state.TNTokenStatus': function () {
  //     this.isModalOpen = true
  //   }
  // },
  mounted() {
    // this.fetchData();
    // console.clear();
    this.updateStep(stepNumbers.home)
    if (this.allSystems.length === 0) {
      this.checkTNToken()
      this.fetchData(this.$i18n.locale)
    }
  },
  computed: {
    ...mapState({
      allSystems: state => state.systems
    }),
    isBtnDisabled() {
      return this.allSystems.length === 0
    }
  }
}
</script>
<style lang="sass" scoped>
.home
  color: $black
  text-align: left
  &__modal
    &-tilte
      padding: 0
      text-align: center
      font-weight: bold
    &-before-tilte
      text-align: center
      font-style: normal
      font-weight: 500
      font-size: rem(12)
      line-height: rem(22)
    &-text
      a
        color: $red
        transition: .5s
        text-decoration: none
        border-bottom: rem(1) dashed rgba($red, .4)
        &:hover
          color: $red-hover
    flex-direction: column
    +media((padding: (320: rem(40) rem(25), 600: rem(56) rem(60))))
    max-width: rem(520)
    width: 95vw
    box-sizing: border-box
  &__wrapper
    display: grid
    grid-template-columns: 1fr
  &__title
    grid-column-start: 2
    font-weight: bold
    +media((padding: (320: rem(32) 0, 960: rem(45) 0)))
    &-bell
      position: absolute
      top: 50%
      display: block
      width: 30px
      height: 30px
      transform: translateY(-50%)
      right: 10px
      cursor: pointer
      &::after
        transform-origin: top
        animation: bell 2s infinite linear
        content: ''
        display: block
        width: 100%
        height: 100%
        position: absolute
        top: 0
        right: 0
        mask-size: 30px 30px
        background-color: $black
        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 611.999 611.999'%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cpath d='M570.107,500.254c-65.037-29.371-67.511-155.441-67.559-158.622v-84.578c0-81.402-49.742-151.399-120.427-181.203 C381.969,34,347.883,0,306.001,0c-41.883,0-75.968,34.002-76.121,75.849c-70.682,29.804-120.425,99.801-120.425,181.203v84.578 c-0.046,3.181-2.522,129.251-67.561,158.622c-7.409,3.347-11.481,11.412-9.768,19.36c1.711,7.949,8.74,13.626,16.871,13.626 h164.88c3.38,18.594,12.172,35.892,25.619,49.903c17.86,18.608,41.479,28.856,66.502,28.856 c25.025,0,48.644-10.248,66.502-28.856c13.449-14.012,22.241-31.311,25.619-49.903h164.88c8.131,0,15.159-5.676,16.872-13.626 C581.586,511.664,577.516,503.6,570.107,500.254z M484.434,439.859c6.837,20.728,16.518,41.544,30.246,58.866H97.32 c13.726-17.32,23.407-38.135,30.244-58.866H484.434z M306.001,34.515c18.945,0,34.963,12.73,39.975,30.082 c-12.912-2.678-26.282-4.09-39.975-4.09s-27.063,1.411-39.975,4.09C271.039,47.246,287.057,34.515,306.001,34.515z M143.97,341.736v-84.685c0-89.343,72.686-162.029,162.031-162.029s162.031,72.686,162.031,162.029v84.826 c0.023,2.596,0.427,29.879,7.303,63.465H136.663C143.543,371.724,143.949,344.393,143.97,341.736z M306.001,577.485 c-26.341,0-49.33-18.992-56.709-44.246h113.416C355.329,558.493,332.344,577.485,306.001,577.485z'/%3E%3Cpath d='M306.001,119.235c-74.25,0-134.657,60.405-134.657,134.654c0,9.531,7.727,17.258,17.258,17.258 c9.531,0,17.258-7.727,17.258-17.258c0-55.217,44.923-100.139,100.142-100.139c9.531,0,17.258-7.727,17.258-17.258 C323.259,126.96,315.532,119.235,306.001,119.235z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A")
    &-counter
      display: flex
      align-items: center
      justify-content: center
      color: $red
      position: absolute
      width: 20px
      height: 20px
      top: -5px
      right: -10px
      z-index: 1
      font-weight: bold
    &-wrapper
      overflow: hidden
      position: relative
      width: 100%
      display: grid
      +media((grid-template-columns: (320: 1fr 12fr 1fr, 768: 1fr 10fr 1fr)))
  &__banner
    &-enter
      position: absolute
      +media((right: (320: rem(24), 768: rem(20))))
      +media((top: (320: rem(-65), 768: rem(20))))
    box-sizing: border-box
    position: relative
    // +media((margin: (990: 0 rem(-15))))
    display: flex
    flex-direction: row
    align-items: center
    justify-content: flex-end
    background-repeat: no-repeat
    background-position: center
    +media((border: (320: none, 768: rem(10) solid $white)))
    +media((background-size: (768: cover, 960: cover)))
    +media((min-height: (320: unset, 768: rem(374))))
    +media((background-image: (320: none, 768: url('~@/assets/img/banner-tablet.jpg'), 960: url('~@/assets/img/banner.jpg'))))
    +media((border-radius: (320: 0, 768: rem(4))))
    +media((padding-right: (320: 0, 768: rem(30))))
    &-mobile
      +media((display: (576: none)))
    &-tablet
      +media((display: (320: none, 576: block, 768: none)))
    &-logo
      position: absolute
      +media((width: (320: rem(108), 400: auto)))
      +media((left: (320: rem(24), 768: rem(20))))
      +media((bottom: (320: rem(88), 768: rem(20))))
      // +media((display: (768: none)))
    &-btn
      cursor: pointer
      outline: none
      text-decoration: none
      background: $red
      display: flex
      flex-direction: row
      align-items: center
      color: $white
      font-style: normal
      font-weight: bold
      font-size: rem(14)
      line-height: 140%
      padding: 0 rem(20)
      box-shadow: 0 rem(3) rem(7) rgba(0, 0, 0, 0.2)
      transition: .5s
      height: rem(56)
      +media((width: (320: 100%, 768: auto)))
      +media((border-radius: (320: 0, 768: rem(4))))
      +media((justify-content: (320: space-between, 768: flex-start)))
      .vld-overlay
        margin-left: rem(20)
        position: relative
        line-height: 0
      &.disabled
        background: #959595
        pointer-events: none
      img
        padding-left: rem(20)
      .preloader
        width: rem(21)
      &:hover
        background: $red-hover-new
        box-shadow: 0 rem(5) rem(20) $black, 0 rem(3) rem(7) rgba(0, 0, 0, 0.2)
  &__info
    display: grid
    +media((padding: (320: rem(32) 0, 768: rem(51) 0)))
    border-bottom: rem(1) solid #D6D6D6
    +media((grid-template-columns: (320: 1fr 12fr 1fr, 768: 1fr 7fr 1fr 3fr)))
    &-text
      grid-column-start: 2
      font-style: normal
      font-weight: 500
      +media((font-size: (320: rem(14), 768: rem(18))))
      +media((line-height: (320: rem(24), 768: rem(32))))
      +media((padding-bottom: (320: rem(32), 768: 0)))
    &-call
      grid-column-start: 4
      +media((grid-column-start: (320: 2, 768: 4)))
      font-style: normal
      font-weight: bold
      font-size: rem(18)
      line-height: rem(22)
      .text
        padding-bottom: rem(12)
      .link
        color: $red
        transition: .5s
        text-decoration: none
        padding-bottom: rem(4)
        border-bottom: rem(1) dashed rgba($red, .4)
        &:hover
          color: $red-hover
  &__minorInfo
    display: grid
    +media((padding: (320: rem(32) 0, 768: rem(51) 0)))
    +media((grid-template-columns: (320: 1fr 12fr 1fr, 768: 1fr 10fr 1fr)))
    &-text
      grid-column-start: 2
      font-style: normal
      font-weight: 500
      font-size: rem(12)
      line-height: rem(22)
@keyframes bell
  0%
    transform: rotate(0deg)
  12.5%
    transform: rotate(25deg)
  25%
    transform: rotate(0deg)
  37.5%
    transform: rotate(-25deg)
  50%
    transform: rotate(0deg)
</style>
